import { useRouter } from 'next/router';

const useRouteDetection = () => {
  const { pathname, query } = useRouter();
  const agencyPage = pathname === '/agencies/[agencies]';

  return { agencyPage, query };
};

export default useRouteDetection;
