import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Bookmark from '../../Bookmark';
import { getCardTime } from '../../../utils/helpers';
import ItemCarousel from '../../ItemCarousel';
import LazyImage from '../../HOC/lazyImage';
import useRouteDetection from '../../../hooks/useRouteDetection';

const ItemCard = props => {
  const { t, i18n } = useTranslation();
  const { agencyPage } = useRouteDetection();
  const getLocationName = ({ city, location }) => {
    if (!(city || location)) {
      return;
    }

    const isDefaultCity = city.id === '1'; // is Baku city
    let locationName = '';

    if (location && isDefaultCity) {
      locationName = location.fullName;
    } else {
      locationName = city.name;
    }

    return locationName;
  };

  const getParamsList = ({ rooms, area, floor, floors }) => {
    const paramsList = [];

    if (rooms) {
      paramsList.push([
        'rooms',
        `${t('cards.item_card.rooms', { count: rooms })}`
      ]);
    }
    if (area) {
      paramsList.push(['area', `${area.value} ${area.units}`]);
    }
    if (floor) {
      paramsList.push([
        'floor',
        `${floor}/${floors} ${t('cards.item_card.floor')}`
      ]);
    }

    return paramsList;
  };

  const {
    path,
    photos,
    photosCount,
    price,
    paidDaily,
    leased,
    city,
    hasBillOfSale,
    hasMortgage,
    hasRepair,
    featured,
    vipped,
    id,
    inBookmarks,
    removeItemHandle,
    carousel,
    itemId,
    setCarousel,
    updatedAt,
    cypress,
    company,
    'cypress-card': card
  } = props;

  const locationName = getLocationName(props);
  const paramsList = getParamsList(props);
  const cardTime = getCardTime(updatedAt, i18n);

  const isCarouselLoaded = itemId === id;
  const [swiper, updateSwiper] = useState(null);
  const [startX, updateStartX] = useState(null);
  const [startY, updateStartY] = useState(null);

  const thumbnail = photos.map((sub, subindex) => (
    <LazyImage key={subindex} src={sub.thumbnail} />
  ));

  const scrollOnStart = e => {
    const touchobj = e.changedTouches[0];
    updateStartX(touchobj.pageX);
    updateStartY(touchobj.pageY);
  };

  const scrollOnMove = e => {
    const touchobj = e.changedTouches[0];
    const distX = touchobj.pageX - startX;
    const distY = touchobj.pageY - startY;
    if (Math.abs(distX) - Math.abs(distY) > 45) {
      const dir = distX < 0 ? 'left' : 'right';
      setCarousel(id);
      if (dir === 'left') {
        updateSwiper('next');
      } else {
        updateSwiper('prev');
      }
    }
  };

  const itemType = company?.targetType?.toLowerCase() || '';

  const onTouchStartHandler = !carousel
    ? null
    : e => (isCarouselLoaded ? null : scrollOnStart(e));

  const onTouchMoveHandler = !carousel
    ? null
    : e => (isCarouselLoaded ? null : scrollOnMove(e));

  const carouselBtns = carousel ? (
    <>
      <div
        role="button"
        tabIndex="0"
        className="swiper-button-next init-slider"
        onClick={() => {
          setCarousel(id);
          updateSwiper('next');
        }}
        onKeyPress={() => setCarousel(id)}
      />
      <div
        role="button"
        tabIndex="0"
        className="swiper-button-prev init-slider"
        onClick={() => {
          setCarousel(id);
          updateSwiper('prev');
        }}
        onKeyPress={() => setCarousel(id)}
      />
    </>
  ) : null;

  const ifCarousel = isCarouselLoaded ? (
    <ItemCarousel
      {...{ photos, carousel, path, photosCount }}
      scrollTo={swiper}
    />
  ) : (
    <>
      {/* eslint-disable react/jsx-no-target-blank */}
      <a
        className="item-card-link"
        href={path}
        target="_blank"
        data-cy="card-link"
      >
        {thumbnail[0]}
      </a>
      {/* eslint-enable react/jsx-no-target-blank */}
    </>
  );

  const cardPricePer = () => {
    if (paidDaily === null || !leased) {
      return false;
    }

    return (
      <span className="item-card-price-per">
        {paidDaily
          ? `/${t('cards.item_card.per_day')}`
          : `/${t('cards.item_card.per_month')}`}
      </span>
    );
  };

  return (
    <div
      className={classNames(
        'item-card',
        { 'item-card_vipped': vipped },
        { 'item-card_featured': featured }
      )}
      data-cy={card}
    >
      {/* eslint-disable react/jsx-no-target-blank */}
      <a
        className={classNames('item-card-link', {
          'item-card-link-zindex': !carousel
        })}
        href={path}
        target="_blank"
        data-cy={cypress}
      />
      {/* eslint-enable react/jsx-no-target-blank */}
      <Bookmark
        itemId={id}
        isActive={inBookmarks}
        removeItemFunc={removeItemHandle}
      />
      <div
        className={classNames('item-card-icons-section', {
          'item-card-icons-section__agency-icon': agencyPage
        })}
      >
        {hasBillOfSale && <div className="bill_of_sale" />}

        {hasMortgage && <div className="mortgage" />}

        {hasRepair && <div className="repair" />}
      </div>
      <div
        className="item-card-preview"
        onTouchStart={onTouchStartHandler}
        onTouchMove={onTouchMoveHandler}
      >
        {ifCarousel}
        {carouselBtns}

        {(featured || vipped) && (
          <div className="products-paid">
            {featured && <span className="featured-icon" />}

            {vipped && <span className="vipped-icon" />}
          </div>
        )}
        {itemType.length > 0 && (
          <div className={`products-label products-label--${itemType}`}>
            {t(`cards.item_card.${itemType}`)}
          </div>
        )}
      </div>
      <div className="item-card-params">
        <div className="abs_block">
          <div className="item-card-price">
            <span className="item-card-price-val">
              {price.value.toLocaleString('ru-RU')}
            </span>
            <span className="item-card-price-cur">{price.currency}</span>
            {cardPricePer()}
          </div>
        </div>
        <div
          className={classNames(
            {
              'normal-weight': agencyPage
            },
            'item-card-params-location'
          )}
        >
          {locationName}
        </div>
        <ul
          className={classNames(
            {
              'normal-weight': agencyPage
            },
            'item-card-params-name'
          )}
        >
          {paramsList.map(([key, value]) => (
            <li key={key}>{value}</li>
          ))}
        </ul>
      </div>

      <div className="item-card-footer">
        <div className="city_when">
          {city.name}, {cardTime}
        </div>
      </div>
    </div>
  );
};

ItemCard.propTypes = {
  path: PropTypes.string,
  photos: PropTypes.array,
  photosCount: PropTypes.number,
  itemId: PropTypes.string,
  company: PropTypes.object,
  price: PropTypes.object,
  city: PropTypes.object,
  leased: PropTypes.bool,
  hasBillOfSale: PropTypes.bool,
  hasMortgage: PropTypes.bool,
  hasRepair: PropTypes.bool,
  featured: PropTypes.bool,
  vipped: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inBookmarks: PropTypes.bool,
  removeItemHandle: PropTypes.func,
  setCarousel: PropTypes.func,
  carousel: PropTypes.bool,
  updatedAt: PropTypes.string,
  paidDaily: PropTypes.bool,
  cypress: PropTypes.string,
  'cypress-card': PropTypes.string
};

ItemCard.defaultProps = {
  carousel: false
};

export default ItemCard;
